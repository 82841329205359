import { Link } from 'gatsby'
import React from 'react'
import {  Grid } from "@material-ui/core";
function components() {
    return (
        <Grid container>
            <Grid item xs={4}>
                <ul id="components-library">
                    <li><Link to="/components/logo">Logo</Link></li>
                    <li><Link to="/components/typography">Typography</Link></li>
                    <li><Link to="/components/button">Button</Link></li>
                    <li><Link to="/components/back-button">Back Button</Link></li>
                    <li><Link to="/components/actions">Actions</Link></li>
                    <li><Link to="/components/steps">Steps</Link></li>
                    <li><Link to="/components/dialog">Dialog</Link></li>
                    <li><Link to="/components/modal">Modal &amp; Modal Video</Link></li>
                    <li><Link to="/components/image">Image</Link></li>
                    <li><Link to="/components/section">Section</Link></li>
                    <li><Link to="/components/tabs">Tabs</Link></li>
                    <li><Link to="/components/card">Card</Link></li>
                    <li><Link to="/components/card-turn">CardTurn</Link></li>
                    <li><Link to="/components/card-person">CardPerson</Link></li>
                    <li><Link to="/components/card-collapse">CardCollapse</Link></li>
                    <li><Link to="/components/card-info">CardInfo</Link></li>
                    <li><Link to="/components/card-actions">CardActions</Link></li>
                </ul>
            </Grid>
            <Grid item xs={4}>
                <ul id="components-library">
                    <li><Link to="/components/input-text">Input Text</Link></li>
                    <li><Link to="/components/input-textarea">Input Textarea</Link></li>
                    <li><Link to="/components/input-select">Input Select</Link></li>
                    <li><Link to="/components/input-select-time">Input Select Time</Link></li>
                    <li><Link to="/components/input-select-date">Input Select Date</Link></li>
                    <li><Link to="/components/input-checkbox">Input Checkbox</Link></li>
                    <li><Link to="/components/input-radio">Input Radio</Link></li>
                    <li><Link to="/components/input-slider">Input Slider</Link></li>
                    <li><Link to="/components/input-answer">Input Answer</Link></li>
                    <li><Link to="/components/input-avatar">Input Avatar</Link></li>
                    <li><Link to="/components/input-slider-mood">Input Slider Mood</Link></li>
                    <li><Link to="/components/input-mood">Input Mood</Link></li>
                    <li><Link to="/components/input-frequency">Input Frequency</Link></li>
                    <li><Link to="/components/input-notifications">Input Notifications</Link></li>
                </ul>
            </Grid>
            <Grid item xs={4}>
                <ul id="components-library">
                    <li><Link to="/components/progress">Progress</Link></li>
                    <li><Link to="/components/alert">Alert</Link></li>
                    <li><Link to="/components/report-survey">Report Survey</Link></li>
                    <li><Link to="/components/report-quiz">Report Quiz</Link></li>
                    <li><Link to="/components/report-cards">Report Cards</Link></li>
                    <li><Link to="/components/report-table">Report Table</Link></li>
                    <li><Link to="/components/report-glance">Report Glance</Link></li>
                    <li><Link to="/components/report-timeline">Report Timeline</Link></li>
                    <li><Link to="/components/report-archive">Report Archive</Link></li>
                    <li><Link to="/components/pet">Pet</Link></li>
                    <li><Link to="/components/slideshow">Slideshow</Link></li>
                    <li><Link to="/components/rocket-timer">Rocket Timer</Link></li>
                    <li><Link to="/components/report-chart">Report Chart</Link></li>
                </ul>
            </Grid>
        </Grid>
    )
}

export default components
